import BrandIcon from '../img/premierlogo.png'
import '../css/Loader.css'

const Loader = () => {
    return (
        <div className="Loader-container">
            <img src={BrandIcon} alt="premierbanklogo" className='loader' />
        </div>
    )
}

export default Loader