import dayjs from "dayjs"
import { useEffect, useState } from "react"
import Logo from '../img/logo-no-background.png'

import '../css/Receipt.css'

const Receipt = ({ db }) => {
    const [pathid, setPathid] = useState(false)
    const [refid, setRefid] = useState(false)
    
    useEffect(() => {
        const urlPathname = window.location.pathname

        const id = urlPathname.substring(11, urlPathname.length)
        const ref = urlPathname.substring(36, urlPathname.length)

        const match = ref.replace(/-/, (e) => {
            return ''
        })

        const func = async () => {
            const { data, error } = await db.from('history').select().eq('id', id)

            if (error) {
                console.log(error)
                return
            }

            setPathid(data)
            setRefid(match)
        }

        func()
    }, [db])

    return (
        <>
            {
                pathid &&
                    pathid.map(d => {
                        return (
                            <div className="r-card" key={d.id}>
                                <div className="r-nav">
                                    <h5>Transaction Details</h5>
                                    <div className="r-icon-container">
                                        {
                                            d.status ?
                                                <h4 style={{color: 'green'}}>Success</h4> :
                                            <h4 style={{color: '#E0A506'}}>Pending</h4>
                                        }
                                    </div>
                                    <h1>
                                        { d.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                                    </h1>
                                    <div className="r-to">
                                        {
                                            d.tag !== localStorage.getItem('tx') && 
                                                <>
                                                    Sent to { d.tag }
                                                </>
                                        }
                                    </div>
                                    <ul className="r-card-body">
                                        {
                                            d.userid === localStorage.getItem('userId') ?
                                                <li>
                                                   Total Debited 
                                                </li> :
                                            <li>
                                                Total Credited
                                            </li>
                                        }
                                        <li>{ d.amount.toLocaleString('en-US', {style: 'currency', currency: 'USD'}) }</li>
                                        {
                                            d.tag === localStorage.getItem('nx') ?
                                                <>
                                                    <li>
                                                        Note
                                                    </li>
                                                    <li>
                                                        {d.description === '' ? 'Null' : d.description}
                                                    </li>
                                                </> :
                                            d.userid === localStorage.getItem('userId') &&
                                                <>
                                                    <li>
                                                        Note
                                                    </li>
                                                    <li>
                                                        {d.description === '' ? 'Null' : d.description}
                                                    </li>
                                                </>
                                        }
                                        <li>Status</li>
                                        <li>{d.status ? 'Completed' : 'Pending'}</li>
                                        {
                                            d.rn !== null && 
                                            <>
                                                <li>Routing Number</li>
                                                <li>{d.rn}</li>
                                            </>
                                        }
                                        <li>Reference ID</li>
                                        <li>{refid && refid+'-'+d.ref}</li>
                                        <li>Date</li>
                                        <li style={{
                                            display: 'flex', 
                                            alignItems: 'center',
                                            justifyContent: 'flex-end', 
                                            gap: '4px',
                                            fontSize: '14px'
                                        }}>
                                            <span>{ dayjs(d.created_at).format('MMM DD, YYYY') }</span> 
                                            <i className="fa-solid fa-circle" style={{fontSize: '5px'}}></i>
                                            <span>{ dayjs(d.created_at).format('hh:mm A')}</span>
                                        </li>
                                    </ul>
                                    <div className="img-container">
                                        <img src={Logo} alt="nova west capital" />
                                    </div>
                                </div>
                            </div>
                        )
                    })
            }
            {/* <button className="r-btn">Download Receipt</button> */}
        </>
    )
}

export default Receipt