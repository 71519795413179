import HistoryHandler from "./handlers/HistoryHandler"

const IntlTransfer = async (bool, a, b, d, db ) => {
    if (bool) {
        const history = await HistoryHandler({ a, b, d, db })

        return history
    }
}

export default IntlTransfer