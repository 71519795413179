const RanVerifier = async (db, num) => {
    const input = document.querySelector('.verify-input input')
    const err = document.querySelector('.verify-err')

    if (input.value === '') {
        err.innerText = 'Invalid verification code.'

        return false
    }

    const e = input.value.replace(/[-]/g, '')

    if (num.toString() !== e) {
        err.innerText = 'Invalid verification code.'
        return false
    }

    const a = await db.from('Accounts').select().eq('userid', localStorage.getItem('userId'))

    if (a.error) {
        console.log(a.error)
        return false
    }

    const s = localStorage.getItem('c')

    const ref = num.toString().substring(0, 5)

    for (let i of a.data) {
        const n = i.cb - Number(s)

        const to = await db.from('Accounts').update({ cb: n }).eq('userid', localStorage.getItem('userId'))

        if (to.error) {
            console.log(to.error)
            return false
        }

        const h = await db.from('history').insert({
            userid: localStorage.getItem('userId'),
            ref: Number(ref),
            status: false,
            type: 'outbound',
            rn: localStorage.getItem('b'),
            amount: s,
            tag: localStorage.getItem('a'),
            description: localStorage.getItem('d')
        })

        if (h.error) {
            console.log(h.error)
            return false
        }

        return true
    } 

}

export default RanVerifier