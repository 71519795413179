import { useNavigate } from 'react-router-dom'
import '../css/Confirm.css'

const Confirm = () => {
    const navigate = useNavigate()

    const an = localStorage.getItem('a')
    const rn = localStorage.getItem('b')
    const am = localStorage.getItem('c')
    const desc = localStorage.getItem('d')

    const verLink = () => {
        navigate('/auth/verify')
    }

    return (
        <>
            <div className="confirm-alert">
                <h4>Please double-check your transaction details before sending, as once processed, transactions sent to the wrong person at another bank may be irreversible.</h4>
            </div>
            <ul className="confirm-details">
                <li>Account Number</li>
                <li>{an}</li>
                <li>Routing Number</li>
                <li>{rn}</li>
                <li>Amount</li>
                <li>{ Number(am).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) }</li>
                <li>Description</li>
                <li>{desc}</li>
            </ul>
            <div className='confirm-btn-container'>
                <button className='confirm-btn' onClick={() => verLink()}>Submit</button>
            </div>
        </>
    )
}

export default Confirm