const Auth = async (db) => {
    if (localStorage.getItem('userId') === '') {
        return false
    }
    
    const { error, data } = await db.from('Users').select().eq('id', String(localStorage.getItem('userId')))

    if (error) {
        console.log(error)
        return false
    }

    if (data.length === 0) {
        return false
    }

    return true
}

export default Auth