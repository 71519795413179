import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import TagHandler from "../components/handlers/TagHandler"
import Logo from "../img/logo-no-background.png"

import '../css/Myinfo.css'

const Myinfo = ({ db }) => {
    const [num, setNum] = useState('')
    const [tag, setTag] = useState('')
    const [first, setfirst] = useState('')
    const [last, setlast] = useState('')
    const [balance, setBalance] = useState('')

    useEffect(() => {
        const t = async () => {
            const { number, tag } = await TagHandler(db)

            setNum(number)
            setTag(tag)
        }

        t()
    }, [db])

    useEffect(() => {
        const t = async () => {
            const { error, data } = await db.from('Users').select()

            if (error) {
                console.log(error)
                return
            }

            for (let d of data) {
                if (d.id === localStorage.getItem('userId')) {
                    setfirst(d.first_name)
                    setlast(d.last_name)
                }
            }
        }

        t()
    }, [db])

    useEffect(() => {
        const t = async () => {
            const { error, data } = await db.from('Accounts').select()

            if (error) {
                console.log(error)
                return
            }

            for (let d of data) {
                if (d.userid === localStorage.getItem('userId')) {
                    setBalance(d.cb)
                }
            }
        }

        t()
    }, [db])

    return (
        <div className="myinfo">
            <div className="myinfo-amount">
                <h1>{balance.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</h1>
            </div>
            <div className="myinfo-list-group">
                <div className="myinfo-list-item">
                    <div className="myinfo-list-left">Account Name</div>
                    <div className="myinfo-list-item-y">
                        <span>{first + ' ' + last}</span>
                    </div>
                </div>
                <hr />
                <div className="myinfo-list-item">
                    <div className="myinfo-list-left">Account ID</div>
                    <div className="myinfo-list-item-y">
                        <span>{num}</span>
                    </div>
                </div>
                <hr />
                <div className="myinfo-list-item">
                    <div className="myinfo-list-left">ProvenTag</div>
                    <div className="myinfo-list-item-y">
                        <span>{tag}</span>
                    </div>
                </div>
            </div>
            <div className="myinfo-img-container">
                <img src={Logo} alt="bank-logo" />
            </div>
            <div className="myinfo-btn">
                <Link to={'/'}>
                    <button><i className="fas fa-chevron-left"></i></button>
                </Link>
            </div>
        </div>
    )
}

export default Myinfo