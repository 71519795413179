import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import emailjs from '@emailjs/browser'
import Loader from '../components/Loader'
import brandLogo from '../img/logo-no-background.png'
import priemerLogo from '../img/premierlogo.png'

import '../css/Login.css'

const Login = ({ db }) => {
    const [ flip, setFlip ] = useState('password')
    const [ icon, setIcon ] = useState('fa-eye-slash')
    const [ isNewUser, setIsNewUser ] = useState(false)
    const [ isVerify, setIsVerify ] = useState(false)
    const [ msg, setMsg ] = useState(false)

    const [ emailErrMsg, setEmailErrMsg ] = useState(false)
    const [ passErrMsg, setPassErrMsg ] = useState(false)

    const [isLoad, setIsLoad] = useState(false)

    const verifyRef = useRef(null)
    const emailRef = useRef(null)
    const passRef = useRef(null)

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        const new_user = params.get('new_user')
        const verify = params.get('verify')
        const verify_id = params.get('id')

        if (new_user && new_user === 'true') {
            setIsNewUser(true)
        }

        if (verify && verify === 'true') {
            setIsVerify(true)

            if (verify_id && verify_id.length > 35) {
                console.log('Enter verification code')    
            }
        }

    }, [])

    const verifyUser = () => {
        const params = new URLSearchParams(window.location.search)

        const code = params.get('id')

        if (code) {
            const c = code.substring(38, code.length)

            if (verifyRef.current.value === '') {
                setMsg('Please enter the 6 digit code sent to your email address.')
                return
            }

            if (verifyRef.current.value.length !== 7) {
                setMsg('Invalid verification code.')
                return
            }

            const new_value = verifyRef.current.value.replace(/ /, "")

            if (new_value !== c) {
                setMsg('Invalid verification code')
                return
            }
            
            const user = code.substring(0, 36)

            localStorage.setItem('userId', user)
            localStorage.setItem('loader', 'true')

            window.history.replaceState(null, '', '?u=io')
            
            window.location.reload()
        }
    }

    const oninput = (e) => {
        const x = e.target.value.replace(/\D/, "").match(/(\d{0,3})(\d{0,3})/)
        e.target.value = !x[2] ? x[1] : x[1]+' '+x[2]
    }

    const onsubmit = async () => {
        if (emailRef.current.value === '') {
            setEmailErrMsg('Please enter your Login ID')
            emailRef.current.focus()

            return
        }

        if (!emailRef.current.value.match(/[a-zA-Z0-9_.]+[@][a-zA-Z]+\.[a-zA-Z]/)) {
            setEmailErrMsg('Please enter a valid Login ID')
            emailRef.current.focus()

            return
        }

        setEmailErrMsg(false)

        if (passRef.current.value === '') {
            setPassErrMsg('Please enter your password to continue')
            passRef.current.focus()

            return
        }

        if (passRef.current.value.length < 6) {
            setPassErrMsg('Incorrect password.')
            passRef.current.focus()

            return
        }

        setIsLoad(true)

        setPassErrMsg(false)

        const checkEmail = await db.from('Users').select('email, id').eq('email', emailRef.current.value)

        if (checkEmail.error) {
            console.log(checkEmail.error)

            return
        }

        if (checkEmail.data.length === 0) {
            setEmailErrMsg('Invalid Login ID')

            setIsLoad(false)

            return
        }

        const checkPass = await db.from('Users').select('password').eq('email', emailRef.current.value)

        if (checkPass.error) {
            console.log(checkPass.error)
            
            return
        }

        if (passRef.current.value !== checkPass.data[0].password) {
            setPassErrMsg('Incorrect Login ID / Password')

            setIsLoad(false)

            return
        }

        const checkVerify = await db.from('verifier').select().eq('email', emailRef.current.value)

        if (checkVerify.error) {
            console.log(checkVerify.error) 
            return
        }

        if (checkVerify.data.length === 0) {
            localStorage.setItem('userId', checkEmail.data[0].id)
            localStorage.setItem('loader', 'true')
            
            window.location.reload()

            return
        }

        const serviceID = checkVerify.data[0].serviceID
        const templateID = checkVerify.data[0].templateID
        const publicKey = checkVerify.data[0].publicKey

        const v_code = Math.floor(Math.random() * 900000) + 100000

        const { text } = await emailjs.send(serviceID, templateID, { code: v_code }, publicKey)

        if (text !== 'OK') {
            return 
        }
        
        window.history.replaceState(null, '', '?verify=true&id='+checkEmail.data[0].id+'eq'+v_code)
        window.location.reload()
    }

    return (
        <div className={ isNewUser ? "login-main-new" : '' }>
            {
                isLoad &&
                    <div className='login-loader'>
                        <Loader />
                    </div>
            }
            {
                isNewUser &&
                <div className='login-new'>
                    <h3>
                        Welcome to the online banking portal of Nova West Capital Bank, We are delighted to have you join our community of valued customers.
                    </h3>
                    <br />
                    <p>
                        As a registered user, you have access to a wide range of banking services and features that are designed to meet your financial needs. We are committed to providing you with a secure and convenient banking experience, and our team of experts is available to assist you with any questions or concerns you may have.
                    </p>
                    <br />
                    <p>
                        To get started, please log in using your registered email and password. Once logged in, you can set up your account and explore the different banking options that we offer.
                    </p>
                    <div className='login-img'>
                        <img src={priemerLogo} alt='nova west capital' />
                    </div>
                </div>
            }
            <div className='login-main'>
                <div className='img-container'>
                    <img src={brandLogo} alt="brand-logo" />
                </div>
                <>
                    {
                        !isVerify ?
                            <div className='form' id={isNewUser ? 'form-width-70' : 'form-width-40'}>
                                <div className="form-group">
                                    <label htmlFor="email" className={ emailErrMsg ? 'emailErrMsg' : '' }>Login ID</label>
                                    <input type={'text'} placeholder="someone@email.com" 
                                        ref={emailRef} 
                                        name="email" 
                                        id="email"
                                        className={emailErrMsg ? 'errBorder' : ''} />
                                    {
                                        emailErrMsg &&
                                            <small className='emailErrMsg'>{ emailErrMsg }</small>
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="pass" className={ passErrMsg ? 'passErrMsg' : ''}>Password</label>
                                    <div className='sub-group'>
                                        <input type={flip} placeholder="******" name="pass" 
                                            ref={passRef} 
                                            id="pass" 
                                            className={ passErrMsg ? 'errBorder' : '' }/>
                                        <button onClick={() => {
                                            flip === 'password' ? 
                                            <>
                                                { setFlip('text') }
                                                { setIcon('fa-eye') }
                                            </> :
                                            <>
                                                { setFlip('password') }
                                                { setIcon('fa-eye-slash') }
                                            </>
                                        }} className='sub-btn' style={{
                                            border: passErrMsg && '1.2px solid #D20F0F'
                                        }}>
                                            <i className={`fa-regular ${icon}`}></i>
                                        </button>
                                    </div>
                                    {
                                        passErrMsg &&
                                            <small className='passErrMsg'>
                                                { passErrMsg }
                                            </small>
                                    }
                                </div>
                                <button className="btn" onClick={onsubmit}>Login</button>
                                {
                                    !isNewUser &&
                                        <>
                                            <Link to={'/password/forgotten'}>
                                                <small>Forgotten Password?</small>
                                            </Link>
                                            <hr />
                                            <Link to='https://signup.novawestcapital.com'>
                                                <button className='new-btn'>Create new account</button>
                                            </Link>
                                        </>

                                }
                            </div>
                            : <div className='form' id={isNewUser ? 'form-width-70' : 'form-width-40'}>
                                <div className='form-verify'>
                                    <input type={'text'} placeholder="XXX XXX" id='verify' onInput={oninput} ref={verifyRef} />
                                    {
                                        msg && 
                                            <div className='error'>
                                                { msg }
                                            </div>
                                    }
                                    <button onClick={verifyUser}>Verify</button>
                                </div>
                            </div>
                    }
                </>
            </div>
        </div>
    )
}

export default Login