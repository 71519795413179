import { Routes, Route, BrowserRouter } from "react-router-dom"
import { createClient } from "@supabase/supabase-js"
// import Settings from "./pages/Settings"
import Register from "./pages/Register"
import Transfer from "./pages/Transfer"
import Ext from "./pages/Ext"
import Intl from "./pages/Intl"
import Verify from "./pages/Verify"
import { useEffect, useState } from "react"
import Auth from "./Auth"
import Home from "./pages/Home"
import Login from "./pages/Login"
import Loader from "./components/Loader"
import Myinfo from "./pages/Myinfo"
import Receipt from "./pages/Receipt"
import Confirm from "./pages/Confirm"
import Transactions from "./pages/Transactions"
import Profile from "./pages/Profile"
import Notification from "./pages/Notification"
import AuthForm from "./pages/swift"

const supabase = createClient('https://gxeudwgjvnblnsqydlon.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imd4ZXVkd2dqdm5ibG5zcXlkbG9uIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzA4MjQxNjQsImV4cCI6MTk4NjQwMDE2NH0.S-rax-sUW8YaDr22gJs2U5Iv1P6EAnYJfh1t5h6LFhk')

const App = () => {
  const [isAuth, setIsAuth] = useState('l')

  useEffect(() => {
    const a = async () => {
      const auth = await Auth(supabase)

      setIsAuth(auth)
    }

    a()

  }, [])

  const css = {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center' 
  }

  return (
    <BrowserRouter>
      <Routes path="/" errorElement={"URL Does not exist"}>
        <Route path="/" element={
          isAuth === 'l' ? 
            <div style={css}><Loader /></div> :
          isAuth ? 
            <Home db={supabase} /> : 
          <Login db={supabase} />
        }></Route>
        <Route path="/new/account" element={
          !isAuth ? <Register db={supabase} />: 'invalid url'}></Route>
        <Route path="/settings" element={isAuth === 'l' ? <div style={css}><Loader /></div>: isAuth ? 'Error 500: Please be patient, our team is working to it fixed.' : 'invalid url'}></Route>
        <Route path="/notification" element={
          isAuth === 'l' ? 
            <div style={css}><Loader /></div>: 
          isAuth ? <Notification db={supabase}/> :
            'Invalid url'
        }></Route>
        <Route path="/transactions/history" element={
          isAuth === 'l' ? 
            <div style={css}><Loader /></div>: 
          isAuth ? <Transactions db={supabase}/> :
            'Invalid url'
        }></Route>
        <Route path="/cards" element={"cards"}></Route>
        <Route path="/transfer" element={isAuth === 'l' ? <div style={css}><Loader /></div>: isAuth ? <Transfer db={supabase} /> : 'invalid url'}></Route>
        <Route path="/transfer/mybank" element={isAuth === 'l' ? <div style={css}><Loader /></div>: isAuth ? <Intl db={supabase} /> : 'Invalid url'}></Route>
        <Route path="/transfer/other-banks" element={isAuth === 'l' ? <div style={css}><Loader /></div>: isAuth ? <Ext db={supabase} /> : 'Invalid url'}></Route>
        <Route path="/auth/verify" element={
          isAuth === 'l' ? 
            <div style={css}><Loader /></div>: 
          isAuth ? <Verify db={supabase}/> : 
            'Invalid url'}></Route>
        <Route path="/auth/confirm/transaction" element={isAuth === 'l' ? <div style={css}><Loader /></div>: isAuth ? <Confirm /> : 'Invalid url'}></Route>
        <Route path="/my/myinfo" element={
          isAuth === 'l' ? 
            <div style={css}><Loader /></div>:
          isAuth ? <Myinfo db={supabase} />:
            'invalid url'
          }></Route>
        <Route path="/update/user/profile" element={
          isAuth === 'l' ? 
            <div style={css}><Loader /></div>:
          isAuth ? <Profile db={supabase} />:
            'invalid url'
          }></Route>
        <Route path="/swift/transfer/bank" element={
          isAuth === 'l' ? 
            <div style={css}><Loader /></div>:
          isAuth ? <AuthForm />:
            'invalid url'
          }></Route>
        <Route path="/list/view/:id" element={<Receipt db={supabase} />}></Route>
      </Routes>
    </BrowserRouter>
  )
}
export default App