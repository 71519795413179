import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Loader from '../components/Loader'

import '../css/Transaction.css'

const Transactions = ({ db }) => {
    const [tData, setTData] = useState(false)
    const [eData, setEData] = useState(false)
    const [rData, setRData] = useState('loading')

    useEffect(() => {
        const t = async () => {
            const { data, error } = await db.from('history').select().eq('userid', localStorage.getItem('userId'))
            
            if (error) {
                console.log(error)
                return
            }

            const d = data.sort((a, b) => {
                return b.created_at.localeCompare(a.created_at)
            })

            if (d) {
                setTData(d)
            }
        }

        t()
    }, [db])

    useEffect(() => {
        const t = async () => {
            const { data, error } = await db.from('history').select().eq('tag', localStorage.getItem('tx'))
            
            if (error) {
                console.log(error)
                return
            }

            const d = data.sort((a, b) => {
                return b.created_at.localeCompare(a.created_at)
            })

            if (d) {
                setEData(d)
            }
        }

        t()
    }, [db])

    useEffect(() => {
        const t = () => {
            if (eData && tData) {
                const n = [...tData, ...eData].sort((a, b) => {
                    return b.created_at.localeCompare(a.created_at)
                })

                setRData(n)
            }
        }

        t()
    }, [db, tData, eData])

    return (
        <div className="transaction">
            {
                rData === 'loading' ?
                    <div className="t-loader">
                        <Loader />
                    </div> :
                rData ?
                    <>
                        <div className="t-nav">
                            <div className="t-back">
                                <Link to={'/'}><i className="fas fa-chevron-left"></i></Link>
                            </div>
                            <div className="t-txt">
                                <h4>Transfer History</h4>
                            </div>
                        </div>
                        {
                            rData.map((a) => {
                                return (
                                    <Link to={'/list/view/'+a.id} key={a.id} className="t-container">
                                        <div className="t-icon-container">
                                            {
                                                a.status ?
                                                    <i className={
                                                        a.type === 'local' ? 
                                                            'fas fa-right-left' : 
                                                        'fas fa-building-columns'}></i>:
                                                <i className={
                                                    a.type === 'outbound' ? 
                                                        'fas fa-building-columns' : 
                                                    'fas fa-right-left'}></i>
                                            }
                                        </div>
                                        <div className="t-txt-container">
                                            {
                                                a.tag === localStorage.getItem('tx') ?
                                                    <>
                                                        <h5>Received</h5>
                                                        <small>
                                                            {
                                                                dayjs(a.created_at).format('MMM DD, YYYY')+' at '+dayjs(a.created_at).format('hh:mm A')
                                                            }
                                                        </small>
                                                    </>
                                                    :
                                                <>
                                                    <h5>You Transfered</h5>
                                                    <small>
                                                        {
                                                            dayjs(a.created_at).format('MMM DD, YYYY')+' at '+dayjs(a.created_at).format('hh:mm A')
                                                        }
                                                    </small>
                                                </>
                                            }
                                        </div>
                                        <div className="t-amount">
                                            {   
                                                a.tag === localStorage.getItem('tx') ?
                                                    '+'+a.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }):
                                                '-'+a.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                                            }
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </> :
                'Empty'
            }
        </div>
    )
}

export default Transactions