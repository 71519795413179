import { Link } from 'react-router-dom'

import '../css/Info.css'

const InfoBtn = () => {
    return (
        <div className="info">
            <Link to='/my/myinfo'>
                <button className="info-body">
                    <i className="fa-solid fa-circle-info"></i>
                    <div className="info-txt">
                        <small>My Banking Information</small>
                    </div>
                </button>
            </Link>
        </div>
    )
}

export default InfoBtn