import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import '../css/notification.css'

const Notification = ({ db }) => {
    const [isNotify, setIsNotify] = useState(false)
    const [isData, setIsData] = useState(false)

    useEffect(() => {
        const f = async () => {
            const { data, error } = await db.from('history').select().eq('userid', localStorage.getItem('userId'))

            if (error) {
                console.log(error)
                return
            }

            for (let i of data) {
                if (i.notify === 'unread') {
                    setIsNotify(true)
                }

                if (data.length === 0) {
                    setIsData(false)
                    return
                }

                data.length = 10

                const sortedData = data.sort((a, b) => {
                    return b.created_at.localeCompare(a.created_at)
                })

                setIsData(sortedData)
            }
        }

        f()
    }, [db])

    useEffect(() => {
        if (!isNotify) {
            return
        }

        const f = async () => {
            const { error } = await db.from('history').update({ notify: 'read' }).eq('userid', localStorage.getItem('userId'))

            if (error) {
                console.log(error)
                return
            }
        }

        f()

    }, [db, isNotify])

    return (
        <>
            <div className="n-nav">
                <Link to={'/'}>
                    <button className="fas fa-chevron-left"></button>
                </Link>
            </div>
            {
                isData ? 
                    <>
                        {
                            isData.map(d => {
                                return (
                                    <div key={d.id}>
                                        {
                                            d.tag === localStorage.getItem('tag') ?
                                                'You are credited with' + d.amount :
                                            <div className="n-card">
                                                <h3>You made transfer of {d.amount.toLocaleString('en-us', { style: 'currency', currency: 'USD'})}</h3>
                                                <small>
                                                    {dayjs(d.created_at).format('MMM DD, YYYY')} at {dayjs(d.created_at).format('hh:mm A')}
                                                </small>
                                            </div> 
                                        }
                                    </div>
                                )
                            })
                        }
                    </> :
                <div className="n-card">
                    You have no new notification
                </div>
            }
        </>
    )
}

export default Notification