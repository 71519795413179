import { useState,useEffect } from "react"
import { Link } from 'react-router-dom'
import Currency from "../Currency"

import '../../css/Amount.css'

const Amount = ({ amt, ver }) => {
    const [ amount, setAmount ] = useState('')
    const [ statement, setStatement ] = useState(false)
    const [ isClicked, setisClicked ] = useState(false)

    useEffect(() => {
        if (amt) {
            setAmount(Currency(amt))
        }
    }, [amt])

    useEffect(() => {
        if (ver === 'yes') {
            setStatement('Your statement of account has been sent to your email. Please check your inbox for the PDF file.')
            return
        }

        setStatement('Please finish setting up your account.')

    }, [ver, isClicked])

    useEffect(() => {
        if (isClicked) {

            setTimeout(() => {
                setisClicked(false)
            }, 5000)
        
        }

    }, [isClicked])

    return (
        <div className="main-amt">
            {
                isClicked && 
                    <div className="main-float">
                        { statement }
                    </div>
            }
            <div className="amount">
                { amount ? <h1 id="amt-Fig">{amount}</h1>: <h1>$0.00</h1>}
            </div>
            <hr/>
            <div className="amount-nav">
                <Link to="tel: +1 (740) 273-2606">
                    <i className="fas fa-phone"></i>
                </Link>
                <Link to="mailTo:support@novawest.com">
                    <i className="fas fa-envelope"></i>
                </Link>
                <Link to="#statement-of-account" onClick={() => setisClicked(true)}>
                    <i className="fas fa-file-alt"></i>
                </Link>
            </div>
            {
                ver === 'yes' ?
                    <Link to='/transfer'>
                        <button className="amount-transfer">
                            <i className="fa-solid fa-right-left"></i>
                            <div className="amount-txt">
                                Transfer
                            </div>
                        </button>
                    </Link> :
                <Link to='#' onClick={() => setisClicked(true)}>
                    <button className="amount-transfer">
                        <i className="fa-solid fa-right-left"></i>
                        <div className="amount-txt">
                            Transfer
                        </div>
                    </button>
                </Link>
            }
        </div>
    )
}

export default Amount