const AccountHandler = async (a, db, ea) => {
    const { error, data } = await db.from('account_info').select()

    if (error) {
        console.log(error)
        return false
    }

    if (a.value === '') {
        ea.innerText = 'Invalid NovaTag.'
        a.focus()
        return false
    }

    const tt = data.filter((e) => e.tag === a.value)

    if (tt.length === 0) {
        ea.innerText = 'Invalid NovaTag'
        a.focus()

        return false
    }
    
    for (let t of tt) {
        if (t.tag === localStorage.getItem('tx')) {
            ea.innerText = 'Invalid NovaTag'
            a.focus()

            return false
        }
    }

    return true
}

export default AccountHandler