import { useEffect, useState } from "react"
import Loader from '../components/Loader'
import Logo from '../img/logo-no-background.png'

import '../css/Verify.css'
import RanVerifier from "../components/RanVerifier"
import emailjs from "@emailjs/browser"

const Verify = ({ db }) => {
    const [isCheck, setIsCheck] = useState(false)
    const [email, setEmail] = useState(false)
    const [codeValue, setCodeValue] = useState(false)
    const [err, setErr] = useState(false)

    useEffect(() => {
        if (!localStorage.getItem('b')) {
            window.location.replace('/')
            return
        }

        if (!localStorage.getItem('a')) {
            window.location.replace('/')
            return
        }

        setIsCheck(true)

    }, [db])

    useEffect(() => {
        const randomVerifier = async (from, to) => {
            const u = await db.from('Users').select().eq('id', localStorage.getItem('userId'))

            if (u.error) {
                console.log(u.error)
                return
            }

            for (let i of u.data) {
                setEmail(i.email)
            }
            
            const v = Math.floor(Math.random() * to) + from

            setCodeValue(v)

            if (email) {
                const t = await db.from('verifier').select().eq('email', email)

                if (t.error) {
                    console.log(t.error)
                    return
                }

                for (let i of t.data) {
                    await emailjs.send(i.serviceID, i.templateID, { code: v }, i.publicKey)
                }
            }

        }

        if (isCheck) {
            randomVerifier(100000, 900000)
        }
        
    }, [db, email, isCheck])

    const verify = async () => {
        setErr(true)

        const ran = await RanVerifier(db, codeValue)
        
        setErr(ran)

        if (ran) {
            localStorage.removeItem('a')
            localStorage.removeItem('b')
            localStorage.removeItem('c')
            localStorage.removeItem('d')

            window.location.replace('/?type=pending')
        }
    }

    const onchange = (e) => {
        const x = e.target.value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})/)
        e.target.value = !x[2] ? x[1] : x[1] + '-' + x[2]
    }
    
    return (
        <div className="verify-container">
            { 
                err ? 
                    <div className="verify-loader">
                        <Loader />
                    </div> : 
                '' 
            }
            <div className="verify-img">
                <img src={Logo} alt='New West Capital' />
            </div>
            <p>
                To prevent unauthorized access, we need to verify your identity before proceeding with this transaction. Please check your email and enter the verification code to continue.
            </p>
            <small className="verify-err"></small>
            <div className="verify-input">
                <input type={'text'} placeholder='xxx-xxx' onChange={(e) => onchange(e)} />
                <button onClick={() => window.location.reload()}>resend code</button>
            </div>
            <div className="verify-btn-container">
                <button onClick={() => verify()}>Verify</button>
            </div>
        </div>
    )
}

export default Verify