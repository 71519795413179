import { useEffect, useState } from "react"
import Navbar from "../components/view-components/Navbar"
import Footer from "../components/view-components/Footer"
import Amount from "../components/view-components/Amount"
import Recent from "../components/Recent"
import InfoBtn from "../components/InfoBtn"

import '../css/Home.css'
import { Link } from "react-router-dom"

const Home = ({ db }) => {
    const [ img, setImg ] = useState('')
    const [ amt, setAmt ] = useState(0)
    const [ msg, setMsg ] = useState(false)
    const [ verified, setVerified ] = useState('checking')
    const [verifier, setVerifier] = useState(false)

    useEffect(() => {
        const image = async () => {
            const { error, data } = await db.from('image').select()

            if (error) {
                console.log(error)
                return
            }

            for (let i of data) {
                if (i.userid === localStorage.getItem('userId')) {
                    setImg(i.img)
                    return
                }
            }
        }

        image()
    }, [db])

    useEffect(() => {
        const amount = async () => {
            const { error, data } = await db.from('Accounts').select().eq('userid', localStorage.getItem('userId'))

            if (error) {
                console.log(error)
                return
            }

            for (let i of data) {
                setAmt(Number(i.cb))

                return
            }
        }

        amount()
    }, [db])

    useEffect(() => {
        const isVerify = async () => {
            const { data, error } = await db.from('Users').select('isverified').eq('id', localStorage.getItem('userId'))

            if (error) {
                console.log(error)
                return
            }
            
            if (!data[0].isverified) {
              setVerified('no')  
              return
            }

            setVerified('yes')
        }

        isVerify()
    }, [db])

    useEffect(() => {
        const f = async () => {
            const { data, error } = await db.from('link').select().eq('userid', localStorage.getItem('userId'))

            if (error) {
                console.log(error)
                return
            }

            if (data.length === 0) {
                setVerifier(false)
                return
            }

            if (!data[0].aval) {
                setVerifier(false)
                return
            }

            setVerifier(data)
        }

        f()
    }, [db])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        
        setMsg(urlParams.get('type'))
    }, [])

    useEffect(() => {
        if (msg) {
            setTimeout(() => {
                const searchQuery = new URLSearchParams(window.location.search)

                searchQuery.set('type', 'seen')

                window.history.replaceState(null, '', '?'+searchQuery.toString())

                setMsg(false)
            }, 5000)
        }
    }, [msg])

    const verifyBtn = async (e) => {
        e.target.innerText = 'Please wait...'

        const { error } = await db.from('link').update({
            aval: false
        }).eq('userid', localStorage.getItem('userId'))

        if (error) {
            console.log(error)
            return
        }

        window.location.href = verifier[0].url
    }

    return (
        <div>
            {
                verifier && <div className="home-modal">
                    <div className="home-modal-content">
                        <p>{verifier[0].msg}</p>
                        <br/>
                        <button onClick={verifyBtn}>Verify</button>
                    </div>
                </div>
            }
            { 
                msg === 'sent' ?
                <div className="sent-msg">
                    <i className="fas fa-check"></i> Sent successfully
                </div> : 
                msg === 'pending' &&
                    <div className="pend-msg">
                        Your transfer is pending, we will notify you when it's successful.
                    </div> 
            }
            {
                verified === 'checking' ? '' : verified === 'yes' ? '' : 
                    <div className="isverified">
                        <Link to="/update/user/profile">
                            Welcome to Nova West Capital Bank. Finish setting up your account by clicking here.
                        </Link>
                    </div>
            }
            <div style={{ backgroundColor: '#2277ef'}}>
                <Navbar img={img !== '' && img} db={db}></Navbar>
                <Amount amt={amt} ver={verified} />
            </div>
            <InfoBtn />
            <Recent db={db} />
            <div style={{height: '200px'}}></div>
            <Footer></Footer>
        </div>
    )
}

export default Home