import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import Loader from "../components/Loader"

import '../css/Profile.css'

const Profile = ({ db }) => {
    const [isVerified, setIsVerified] = useState(false)
    const [isImg, setIsImg] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [isProfile, setIsProfile] = useState(false)

    const imgRef = useRef(null)

    useEffect(() => {
        const f = async () => {
            const { error, data } = await db.from('Users').select('isverified').eq('id', localStorage.getItem('userId'))

            if (error) {
                console.log(error)
                return
            }

            if (data[0].isverified) {
                setIsVerified('yes')

                return
            }

            setIsVerified('no')
        }

        f()

    }, [db])

    useEffect(() => {
        const f = async () => {
            const { data, error } = await db.from('image').select('userid').eq('userid', localStorage.getItem('userId'))

            if (error) {
                console.log(error)
                return
            }

            if (data.length === 0) {
                setIsProfile(true)
                return
            }

            setIsProfile(false)
        }

        f()
        
    })

    const imgFunc = () => {
        imgRef.current.click()
    }

    const finish = async () => {
        setIsLoader(true)

        if (!imgRef.current.files[0]) {
            alert('Please upload a photo before updating.')
            setIsLoader(false)

            return
        }

        try {
            const { data } = await axios.post('https://api.imgbb.com/1/upload?key=4b0b2a4849d18bd1046fce1eb4283089', {
                image: imgRef.current.files[0]
            }, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })

            if (!data.success) {
                alert('Invalid image')
                setIsLoader(false)
                
                return
            }
            
            const { error } = await db.from('image').insert({
                img: data.data.image.url,
                userid: localStorage.getItem('userId')
            })

            if (error) {
                console.log(error)
                return
            }

            window.location.replace('/')

        } catch (error) {
            console.log(error)
            return
        }
    }

    const onchange = () => {
        if (!imgRef.current.files[0]) {
            return
        }
        
        const img = URL.createObjectURL(imgRef.current.files[0])

        setIsImg(img)
    }

    return (
        <>
            <input type={'file'} id="img" onChange={onchange} ref={imgRef} accept="image/*" hidden capture />
            
            {
                isLoader && <Loader />
            }
            {
                isVerified === 'yes' ?
                     '' :
                isVerified === 'no' ?
                    <>
                        <div className="profile-nav">
                            <Link to={'/'}>
                                <button className="fas fa-chevron-left"></button>
                            </Link>
                        </div>
                        <div className="profile-text">
                            <p>
                                We are currently verifying your account information to ensure the security and integrity of our services. This process is essential in order to fully activate your account and provide you with all the features and benefits of our platform.
                            </p>
                        </div>
                        {
                            isProfile &&
                               <>
                                    <div className="profile-img-container">
                                        <div className="label">Update profile image</div>
                                        <div className="profile-img" onClick={() => imgFunc()}>
                                            {
                                                isImg && 
                                                    <img src={isImg} alt="user" />
                                            }
                                            <i className="fas fa-camera"></i>
                                        </div>
                                    </div>
                                    <div className="profile-btn-container">
                                        <button onClick={finish}>Update</button>
                                    </div>
                               </> 
                        }
                    </> :
                ''
            }
        </>
    )
}

export default Profile