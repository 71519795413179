import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import IntlEngine from '../components/IntlEngine'
import IntlTransfer from '../components/IntlTransfer'
import Loader from '../components/Loader'
import '../css/Intl.css'

const Intl = ({ db }) => {
    const [load, setLoad] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [urlMsg, setUrlMsg] = useState(false)

    const navigate = useNavigate()

    const engine = async () => {
        setLoad(true)

        const { bool, a, b, d } = await IntlEngine(db)

        if (bool) {
            const { bl, type } = await IntlTransfer(bool, a, b, d, db)

            setLoad(bl)
            setUrlMsg(type)
            setRedirect(bl)
        } else {
            setLoad(false)
        }
    }

    useEffect(() => {
        const ran = Math.floor(Math.random() * 900000000) + 100000000
        const encrypter = 'qwuiwWHJSkdkkds'+ran+'potknsjdWi773612'

        if (redirect) {
            navigate('/?type='+urlMsg+'&id='+encrypter)
        }

    }, [db, redirect, urlMsg, navigate])

    return (
        <div className='intl'>  
            {
                load ?
                    <div className='intl-loader'>
                        <Loader />
                    </div> :
                ''
            }
            <div className='intl-back'>
                <Link to='/transfer'><i className='fas fa-chevron-left'></i></Link>
            </div>
            <div className="intl-form">
                <div className="intl-form-group">
                    <label htmlFor="acct">NovaTag</label>
                    <input type={'text'} id="acct" name="acct" />
                    <small id='ext-acc'></small>
                </div>
                <div className="intl-form-group">
                    <label htmlFor="amt">Amount</label>
                    <input type={'text'} id="amt" name="amt" />
                    <small id='ext-amt'></small>
                </div>
                <div className="intl-form-group">
                    <label htmlFor="desc">Description</label>
                    <textarea id="desc"></textarea>
                </div>
            </div>
            <div className='intl-btn-container'>
                <button onClick={() => engine()}>Send</button>
            </div>
        </div>
    )
}

export default Intl