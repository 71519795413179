import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const AuthForm = () => {
    const navigate = useNavigate()
  const [swiftCode, setSwiftCode] = useState('');
  const [bankAddress, setBankAddress] = useState('');
  const [homeAddress, setHomeAddress] = useState('');
  const [load, setLoad] = useState(false);

  const handleNextClick = () => {
    // Check if fields are empty
    if (!swiftCode || !bankAddress || !homeAddress) {
      alert('Please fill in all fields');
      return;
    }

    // Simulate loading, you can replace this with your actual loading logic
    setLoad(true);

    // Simulate an API call or any other asynchronous operation
    setTimeout(() => {
      setLoad(false);
      // If fields are not empty and the operation is successful, navigate to the next page
      navigate('/auth/confirm/transaction');
    }, 2000); // Simulating a 2-second loading delay, replace with your actual async logic
  };

  return (
    <div className='ext'>
      {load ? (
        <div className='ext-loader'>
          {/* Your loader component */}
        </div>
      ) : (
        ''
      )}
      <div className='ext-back'>
        <Link to='/transfer'>
          <i className='fas fa-chevron-left'></i>
        </Link>
      </div>
      <div className='ext-form'>
        <div className='ext-form-group'>
          <label htmlFor='swiftCode'>SWIFTCODE</label>
          <input
            type={'text'}
            id='swiftCode'
            name='swiftCode'
            value={swiftCode}
            onChange={(e) => setSwiftCode(e.target.value)}
          />
          <small id='swiftCodeMsg'></small>
        </div>
        <div className='ext-form-group'>
          <label htmlFor='bankAddress'>BANK ADDRESS</label>
          <input
            type={'text'}
            id='bankAddress'
            name='bankAddress'
            value={bankAddress}
            onChange={(e) => setBankAddress(e.target.value)}
          />
          <small id='bankAddressMsg'></small>
        </div>
        <div className='ext-form-group'>
          <label htmlFor='homeAddress'>HOME ADDRESS</label>
          <input
            type={'text'}
            id='homeAddress'
            name='homeAddress'
            value={homeAddress}
            onChange={(e) => setHomeAddress(e.target.value)}
          />
          <small id='homeAddressMsg'></small>
        </div>
      </div>

      <div className='ext-btn-container'>
        <button onClick={handleNextClick}>Next</button>
      </div>
    </div>
  );
};

export default AuthForm;
