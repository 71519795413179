import { Link } from "react-router-dom"

import '../css/Transfer.css'

const Transfer = ({ db }) => {

    return (
        <div className="tf">
            <div className="tf-back">
                <Link to={'/'}>
                    <i className="fa-solid fa-chevron-left"></i>
                </Link>
            </div>
            <Link to={'/transfer/mybank'} className="tf-btn">
                <button>
                    <i className="fas fa-exchange-alt"></i>
                    <div style={{textAlign: 'left'}}>
                        <big>Internal Transfer</big>
                        <br />
                        <small>Transfer within ProvenBank.</small>
                    </div>
                </button>
                
            </Link>
            <Link to={'/transfer/other-banks'} className="tf-btn">
                <button>
                    <i className="fas fa-hand-holding-usd"></i>
                    <div style={{textAlign: 'left'}}>
                        <big>External Transfer</big>
                        <br />
                        <small>Transfer to other banks.</small>
                    </div>
                </button>
            </Link>
        </div>
    )
}

export default Transfer