const TagHandler = async (db) => {
    const { error, data } = await db.from('account_info').select()

    if (error) {
        console.log(error)
        return
    }

    let usrid = {}

    for (let d of data) {
        if (d.userid === localStorage.getItem('userId')) {
            usrid = d   
        }
    }

    return usrid
}

export default TagHandler