import { useEffect, useState } from 'react'
import UserAuth from '../components/UserAuth'
import '../css/Register.css'

const Register = ({ db }) => {
    const [auth, setAuth] = useState(0)

    useEffect(() => {
        const authSync = async () => {
            const ath = await UserAuth(db)

            if (ath) {
                setAuth(ath)
                return
            }

            setAuth(false)
        }

        authSync()

    }, [db])
    return (
        auth === 0 ?
            '' :
        auth ?
            <>
                { window.location.replace('/') }
            </> : 
        <div className="reg-main">
            <div className="reg-form-group">
                <input type={'text'} placeholder='Email Address' id="reg-email" />
            </div>
            <div className='btn-container'>
                <button>NEXT</button>
            </div>
        </div>
    )
}

export default Register