import TagHandler from "./TagHandler"

const UserHandler = async (db) => {
    const { error, data } = await db.from('history').select()

    if (error) {
        console.log(error)
        return
    }

    let usr = []

    for (let d of data) {
        const { number, tag, userid } = await TagHandler(db)

        localStorage.setItem('nx', number)
        localStorage.setItem('tx', tag)

        if (d.userid === userid) {
            usr.push(d)
        } else if (d.tag === tag) {
            usr.push(d)
        } else if (d.tag === number.toString()) {
            usr.push(d)
        }
    }

    return usr
}

export default UserHandler