import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import RecentHandler from "./errorhandlers/RecentHandler"
import Loader from "./Loader"

import '../css/Recent.css'
import dayjs from "dayjs"

const Recent = ({ db }) => {
    const [data, setData] = useState('loading')

    useEffect(() => {
        const tx = async () => {
            const d = await RecentHandler(db)
            
            d.length = 5
            
            setData(d)
        }

        tx()

    }, [db])

    const tx = localStorage.getItem('tx')
    const nx = localStorage.getItem('nx')

    return (
        <div className="recent">
            {
                data === 'loading' ?
                    <div className="recent-loader">
                        <Loader />
                    </div> :
                data ?
                    <div className="card">
                        <div className="card-header">
                            <span>Recent</span>
                            <Link to="/transactions/history">VIEW ALL</Link>
                        </div>
                        {
                            data.map((d) => {
                                return (
                                    <div key={d.id} className="card-body" id={d.id}>
                                        { 
                                            d.status ?
                                                <Link to={'/list/view/'+d.id}>
                                                    {
                                                        d.rn === null ?
                                                            <div className="card-icon">
                                                                <i className="fa-solid fa-building-columns"></i>
                                                            </div>:
                                                        <div className="card-icon">
                                                            <i className="fas fa-hand-holding-usd"></i>
                                                        </div>
                                                    }
                                                    <>
                                                        {
                                                            d.tag === tx ?
                                                                <div className="card-name"> 
                                                                    <span>Received</span><br/>
                                                                    <small className="card-date">{dayjs(d.created_at).format('MM/YY')}</small>
                                                                </div> : 
                                                            d.tag === nx ? 
                                                                <div className="card-name"> 
                                                                    <span>Received</span><br/>
                                                                    <small className="card-date">{dayjs(d.created_at).format('MM/YY')}</small>
                                                                </div> :  
                                                            <div className="card-name"> 
                                                                <span>Transferred</span><br/>
                                                                <small className="card-date">{dayjs(d.created_at).format('MM/YY')}</small>
                                                            </div> 
                                                        }
                                                    </>
                                                    <>
                                                        {
                                                            d.tag === tx ?
                                                                <div className="card-amount"> 
                                                                    <span style={{color: '#389a1b'}}>
                                                                        {
                                                                            '+'+d.amount.toLocaleString('en-US', {
                                                                                style: 'currency',
                                                                                currency: 'USD'
                                                                            })
                                                                        }
                                                                    </span>
                                                                </div> : 
                                                            d.tag === nx ? 
                                                                <div className="card-amount"> 
                                                                    <span style={{color: '#389a1b'}}>
                                                                        {
                                                                            '+'+d.amount.toLocaleString('en-US', {
                                                                                style: 'currency',
                                                                                currency: 'USD'
                                                                            })
                                                                        }
                                                                    </span>
                                                                </div> :  
                                                            <div className="card-amount"> 
                                                                <span>
                                                                    {
                                                                        '-'+d.amount.toLocaleString('en-US', {
                                                                            style: 'currency',
                                                                            currency: 'USD'
                                                                        })
                                                                    }
                                                                </span>
                                                            </div> 
                                                        }
                                                    </>
                                                </Link> :
                                            <>
                                                {
                                                    d.userid === localStorage.getItem('userId') && 
                                                        <Link to={'/list/view/'+d.id}>
                                                            {
                                                                d.rn === null ?
                                                                    <div className="card-icon">
                                                                        <i className="fa-solid fa-building-columns" style={{backgroundColor: '#FFCC00'}}></i>
                                                                    </div>:
                                                                <div className="card-icon">
                                                                    <i className="fas fa-hand-holding-usd" style={{backgroundColor: '#FFCC00'}}></i>
                                                                </div>
                                                            }
                                                            <>
                                                                {  
                                                                    <div className="card-name"> 
                                                                        <span>Pending Transfer</span><br/>
                                                                        <small className="card-date">{dayjs(d.created_at).format('MM/YY')}</small>
                                                                    </div> 
                                                                }
                                                            </>
                                                            <>
                                                                {
                                                                    d.tag === tx ?
                                                                        <div className="card-amount"> 
                                                                            <span style={{color: '#389a1b'}}>
                                                                                {
                                                                                    '+'+d.amount.toLocaleString('en-US', {
                                                                                        style: 'currency',
                                                                                        currency: 'USD'
                                                                                    })
                                                                                }
                                                                            </span>
                                                                        </div> : 
                                                                    d.tag === nx ? 
                                                                        <div className="card-amount"> 
                                                                            <span style={{color: '#389a1b'}}>
                                                                                {
                                                                                    '+'+d.amount.toLocaleString('en-US', {
                                                                                        style: 'currency',
                                                                                        currency: 'USD'
                                                                                    })
                                                                                }
                                                                            </span>
                                                                        </div> :  
                                                                    <div className="card-amount"> 
                                                                        <span>
                                                                            {
                                                                                '-'+d.amount.toLocaleString('en-US', {
                                                                                    style: 'currency',
                                                                                    currency: 'USD'
                                                                                })
                                                                            }
                                                                        </span>
                                                                    </div> 
                                                                }
                                                            </>
                                                        </Link>
                                                }
                                            </>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>:
                'No transactions'
            }
        </div>
    )
}

export default Recent