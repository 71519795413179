import { Link } from "react-router-dom"

import '../../css/Footer.css'

const Footer = () => {
    const logout = () => {
        localStorage.clear()
        window.location.replace(window.location.origin+'/')
    }
    
    return (
        <footer>
            <Link to='/'>
                <div className="footer-left">
                    <i className="fa fa-home"></i>
                    <small>Home</small>
                </div>
            </Link>
            <Link to='/#logout' onClick={() => logout()}>
                <div className="footer-right">
                    <i className="fa-solid fa-arrow-right-from-bracket"></i>
                    <small>Logout</small>
                </div>
            </Link>
        </footer>
    )
}

export default Footer