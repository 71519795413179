const UserAuth = async (db) => {
    const { error, data } = await db.from('Users').select()

    let usr = ''

    if (error) {
        console.log(error)
        return
    }

    for (let i of data) {
        if (i.id === localStorage.getItem('userId')) {
            usr = true
            return usr
        }
    }
}

export default UserAuth