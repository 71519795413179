import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../../css/Navbar.css'

const Navbar = ({ db, img }) => {
    const [notLen, setNotLen] = useState(0)

    useEffect(() => {
        const n = async () => {
            const id = localStorage.getItem('userId')

            const { data, error } = await db.from('history').select().eq('userid', id)

            if (error) {
                console.log(error)
                return
            }

            const b = []

            for (let i of data) {
                if (i.notify === 'unread') {
                    b.push(i.notify)
                }
            }

            setNotLen(b.length)
        }

        n()
    }, [db])
    return (
        <nav>
            <div className="left-nav">
                <Link to='/settings'>
                    {
                        img ?
                        <img src={img} alt='user' /> :
                        <i className='fa fa-user'></i>
                    }
                </Link>
            </div>
            <div className="center-nav">
                {/* center */}
            </div>
            <div className="right-nav">
                <Link to={'/notification'}>
                    { 
                        notLen !== 0 &&
                            <span className='count'> 
                                {notLen}
                            </span> 
                    }
                    <i className='fa fa-bell'></i>
                </Link>
                <Link to={'/transactions/history'}>
                    <i className="fa-solid fa-circle-dollar-to-slot"></i>
                </Link>
            </div>
        </nav>
    )
}

export default Navbar