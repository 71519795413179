const AmountHandler = async (b, db, eb) => {
    const id = localStorage.getItem('userId')

    const { error, data } = await db.from('Accounts').select().eq('userid', id)

    if (error) {
        console.log(error)
        return false
    }

    if (b.value === '') {
        eb.innerText = 'Invalid Amount'
        b.focus()
        return
    }

    for (let i of data) {
        if (b.value > i.cb) {
            eb.innerText = 'Invalid Amount: Amount should not be greater than current balance. Your current balance is '+ i.cb.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD'
                })

            b.focus()
            
            return false
        }
    }

    return true
}

export default AmountHandler