import UserHandler from "../handlers/UserHandler"

const RecentHandler = async (db) => {
    const usr = await UserHandler(db)

    const sorting = usr.sort((a, b) => {
        return b.created_at.localeCompare(a.created_at)
    })

    return sorting
}

export default RecentHandler