import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ExtEngine from '../components/ExtEngine'
import Loader from '../components/Loader'
import '../css/Ext.css'

const Ext = ({ db }) => {
    const [load, setLoad] = useState(false)
    const [verifyUrl, setVerifyUrl] = useState(false)

    const navigate = useNavigate()

    const engine = async () => {
        setLoad(true)

        const { bool, a, b, c, d, } = await ExtEngine(db)

        setLoad(bool)
        setVerifyUrl(bool)

        localStorage.setItem('a', a)
        localStorage.setItem('b', b)
        localStorage.setItem('c', c)
        localStorage.setItem('d', d)
    }

    useEffect(() => {
        if (verifyUrl) {
            navigate('/swift/transfer/bank')
        }

    }, [db, verifyUrl, navigate])

    return (
        <div className='ext'>  
            {
                load ? 
                    <div className='ext-loader'>
                        <Loader />
                    </div> :
                ''
            }
            <div className='ext-back'>
                <Link to='/transfer'><i className='fas fa-chevron-left'></i></Link>
            </div>
            <div className="ext-form">
                <div className="ext-form-group">
                    <label htmlFor="acct">Account number</label>
                    <input type={'text'} id="acct" name="acct" />
                    <small id='acctMsg'></small>
                </div>
                <div className="ext-form-group">
                    <label htmlFor="rn">Routing Number</label>
                    <input type={'text'} id="rn" name="rn" />
                    <small id='rnMsg'></small>
                </div>
                <div className="ext-form-group">
                    <label htmlFor="amt">Amount</label>
                    <input type={'text'} id="amt" name="amt" />
                    <small id='amtMsg'></small>
                </div>
                <div className="ext-form-group">
                    <label htmlFor="desc">Description</label>
                    <textarea id="desc"></textarea>
                    <small></small>
                </div>
            </div>

            <div className='ext-btn-container'>
                <button onClick={() => engine()}>Send</button>
            </div>
        </div>
    )
}

export default Ext