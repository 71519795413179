const ExtEngine = async (db) => {
    const a = document.querySelector('.ext-form-group #acct')
    const r = document.querySelector('.ext-form-group #rn')
    const m = document.querySelector('.ext-form-group #amt')
    const d = document.querySelector('.ext-form-group #desc')

    const am = document.querySelector('.ext-form-group #acctMsg')
    const rm = document.querySelector('.ext-form-group #rnMsg')
    const mt = document.querySelector('.ext-form-group #amtMsg')

    const usr = localStorage.getItem('userId')

    const { error, data } = await db.from('Accounts').select().eq('userid', usr)

    if (error) {
        console.log(error)
        return false
    }

    if (a.value === '') {
        am.innerText = 'Invalid Account Number.'
        a.focus()
        return false
    }

    if (a.value.match(/[a-z]+/)) {
        am.innerText = 'Account Number should only contain numbers.'
        a.focus()
        return false
    }

    if (r.value === '') {
        rm.innerText = 'Invalid Routing Number'
        r.focus()
        return false
    }

    if (!r.value.match(/[a-zA-Z0-9]+/)) {
        rm.innerText = 'Routing Number should only contain numbers.'
        r.focus()
        return false
    }

    if (m.value.match(/^[0]/)) {
        mt.innerText = `Amount shouldn't start with 0.`
        m.focus()
        return false
    }

    if (m.value === '') {
        mt.innerText = 'Invalid Amount'
        m.focus()
        return false
    }

    if (m.value.match(/[a-z]+/)) {
        mt.innerText = 'Amount should only contain numbers.'
        m.focus()
        return false
    }

    for (let i of data) {
        if (Number(m.value > i.cb)) {
            mt.innerText = 'Insufficient amount: Amount greater than current balance. Your current balance is '+ i.cb.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
            m.focus()
            return false
        }
    }

    return {
        bool: true,
        a: a.value,
        b: r.value,
        c: m.value,
        d: d.value
    }
}

export default ExtEngine