import AccountHandler from "./errorhandlers/AccountHandler"
import AmountHandler from "./errorhandlers/AmountHandler"

const IntlEngine = async (db) => {
    const a = document.querySelector('.intl-form-group #acct')
    const b = document.querySelector('.intl-form-group #amt')
    const d = document.querySelector('.intl-form-group #desc')

    const ea = document.querySelector('.intl-form-group #ext-acc')
    const eb = document.querySelector('.intl-form-group #ext-amt')

    const accountAlert = await AccountHandler(a, db, ea)

    if (!accountAlert) {
        return false
    }

    const amountAlert = await AmountHandler(b, db, eb)

    if (!amountAlert) {
        return false
    }

    return {
        bool: true,
        a: a.value,
        b: b.value,
        d: d.value
    }
}

export default IntlEngine