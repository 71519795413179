const refGen = Math.floor(Math.random() * 90000) + 10000

const sender = async (db, a, b, de) => {
    const id = localStorage.getItem('userId')

    const ca = await db.from('Accounts').select().eq('userid', id)

    if (ca.error) {
        console.log(ca.error)
        return false
    }

    for (let d of ca.data) {
        const nb = d.cb - Number(b)

        const up = await db.from('Accounts').update({ cb: nb }).eq('userid', id)

        if (up.error) {
            console.log(up.error)
            return false
        }

        const to = await db.from('history').insert({
            amount: b,
            tag: a,
            status: d.bot && false,
            userid: d.userid,
            description: de,
            ref: refGen
        })

        if (to.error) {
            console.log(to.error)
            return false
        }

        return true
    }
}

const receiver = async (db, a, b) => {
    const id = await db.from('account_info').select().eq('tag', a)

    if (id.error) {
        console.log(id.error)
        return false
    }

    for (let i of id.data) {
        const usr = await db.from('Accounts').select().eq('userid', i.userid)

        if (usr.error) {
            console.log(usr.error)
            return false
        }

        for (let u of usr.data) {
            const amt = u.cb + Number(b)
            const nb = await db.from('Accounts').update({ cb: amt }).eq('userid', i.userid)

            if (nb.error) {
                console.log(nb.error)
                return false
            }

            return true
        }
    }
}

const HistoryHandler = async ({ a, b, c, d, db }) => {
    const usrid = localStorage.getItem('userId')

    const s = await sender(db, a, b, d)

    if (s) {
        const status = await db.from('history').select().eq('userid', usrid)

        if (status.error) {
            console.log(status.error)
            return { bl: false }
        }

        let rs = {}

        for (let i of status.data) {
            if (refGen === i.ref) {
                rs = { stat: i.status }
            } 
        }

        if (rs.stat) {
            const r = await receiver(db, a, b)

            return { bl: r, type: 'sent' }
        }

        return { bl: true, type: 'pending' }
    }
}

export default HistoryHandler